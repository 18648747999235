import React, { FC, ReactNode } from 'react'
import { TextField as MuiTextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'

const ITEM_HEIGHT = 80
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
  },
}

type Props = {
  name: string
  control: any
  value?: any
  label?: string
  type?: 'text' | 'password' | 'email' | 'search'
  className?: any
  multiline?: any
  variant?: any
  InputProps?: object
  disabled?: boolean
  select?: boolean
  children?: ReactNode
  placeholder?: string
  onChange?: any
  onKeyDown?: any
}

const TextField: FC<Props> = ({
  label,
  name,
  type,
  control,
  placeholder,
  className,
  multiline,
  variant,
  onChange,
  value,
  onKeyDown,
  select,
  children,
  // InputProps,
  ...rest
}) => {
  if (multiline) {
    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <MuiTextField
            size='medium'
            label={label}
            onChange={onChange}
            multiline
            variant={variant}
            minRows={3}
            type={type}
            inputRef={ref}
            value={value}
            error={!!error}
            helperText={error?.message}
            className={className}
            fullWidth
            {...rest}
          />
        )}
      />
    )
  }

  if (select) {
    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, ref, value, onBlur },
          fieldState: { error },
        }) => (
          <FormControl sx={{ width: '100%', mb: 4 }} error={!!error}>
            <InputLabel>{label}</InputLabel>
            <Select
              onChange={onChange}
              onBlur={onBlur}
              label={label}
              value={value}
              inputRef={ref}
              input={<OutlinedInput label={label} />}
              // MenuProps={MenuProps}
              fullWidth
              sx={{ textTransform: 'capitalize' }}
              {...rest}
            >
              {children}
            </Select>
            <FormHelperText>{error && error.message}</FormHelperText>
          </FormControl>
        )}
      />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <MuiTextField
          size='medium'
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          variant={variant}
          inputRef={ref}
          value={value}
          error={!!error}
          helperText={error?.message}
          className={className}
          fullWidth
          {...rest}
        />
      )}
    />
  )
}

export default TextField
