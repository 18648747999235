import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    border: '1px solid #E5E5EA',
    background: '#FFF',
    borderRadius: '4px',
    padding: '16px',
  },
})

export default useStyles
