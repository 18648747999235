import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import useStyles from '@/reusables/common/TabNav/styled.tabNav'

type Props = {
  navs: Array<string>
  value: number
  // children: ReactNode
  handleChange: any
  width?: string
}

function TabNav({ navs, value, handleChange, width }: Props) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Tabs
        indicatorColor='primary'
        textColor='primary'
        value={value}
        onChange={handleChange}
        aria-label='tab navigation'
      >
        {navs.map((nav) => (
          <Tab key={nav} label={nav} disableRipple />
        ))}
      </Tabs>
    </Box>
  )
}

export default TabNav
