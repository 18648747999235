import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

const useStyles = makeStyles({
    root: {
        ' & pageNotFoundContentWrapper': {
            marginTop: '11rem',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
    }


})

export default useStyles
