export const BasePaths = {
  ADMIN: '/admin',
  AGGREGATOR: '/aggregator',
}

export const AuthPaths = {
  SIGNIN: 'signin',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  VERIFY_TOKEN: 'verify-user',
  CREATE_NEW_PASSWORD: 'create-new-password',
  PRIVACY_POLICY: 'privacy-policy',
}

export const DashboardPath = {
  OVERVIEW: 'overview',
  OVERVIEWS: 'overview',
  MICROSAVINGS: 'microsavings',
  MICROPENSION: 'micropension',
  MICROINSURANCE: 'microinsurance',
  MICROINVESTMENT: 'microinvestment',
  RETAILERS: 'retailers',
  AGGREGATOR: 'aggregators',
  AGENTS: 'agents',
  REQUESTS: 'requests',
  SETTINGS: 'settings',
  SUPPORT: 'support',
  CUSTOMERS: 'customers',
  TERMINALS: 'terminals',
}
