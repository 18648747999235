import React, { ReactNode } from 'react'
import { Modal as CModal } from '@mui/material'
import Box from '@mui/material/Box'

import useStyles from '@/reusables/common/Modal/styled.modal'

import useModal from '@/hooks/custom-hooks/useModal'

function Modal({
  children,
  modalName,
}: {
  children: ReactNode
  modalName: string
}) {
  const classes = useStyles()
  const [state] = useModal()

  return (
    <CModal open={state.modalName === modalName} disableAutoFocus>
      <Box className={classes.root}>
        <Box sx={{ pb: 0, px: 0 }}>{children}</Box>
      </Box>
    </CModal>
  )
}

export default Modal
