// import { el } from 'date-fns/locale'

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value: number) {
  return `${value / 16}rem`
}

/* eslint-disable no-restricted-syntax */
export const queryParamsHelper = (queryParams: any) => {
  let queries = '?'

  for (let [key, value] of Object.entries(queryParams || {})) {
    if (
      !!key &&
      key !== undefined &&
      key !== null &&
      value !== '' &&
      value !== undefined
    ) {
      if (key === 'page' && value === 0 && value !== undefined) {
        value = 1
      }
      queries += `${key}=${value}&`
    }
  }
  // eslint-disable-next-line no-unsafe-optional-chaining
  return queries?.substring(0, queries?.length - 1)
}

export const formatPhoneNumber = (phone: string) => {
  if (phone?.startsWith('+234')) {
    return phone?.replace('+234', '0')
  }
  return phone
}

export const capitalizeCompanyName = (companyName: any) => {
  // const [first, last] = companyName.split(' ')

  // return (
  //   first.charAt(0).toUpperCase() +
  //   first.slice(1).toLowerCase() +
  //   ' ' +
  //   last.charAt(0).toUpperCase() +
  //   last.slice(1).toLowerCase()
  // )

  const output = []
  const str = companyName.split(' ')

  for (const char of str) {
    output.push(char.charAt(0).toUpperCase() + char.slice(1).toLowerCase())
  }

  return output.join(' ')
}

export const titleCase = (username: any) => {
  let str
  const output = []

  if (username.includes('_')) {
    str = username.split('_')
  } else {
    str = username.split(' ')
  }

  for (const char of str) {
    output.push(char.charAt(0).toUpperCase() + char.slice(1).toLowerCase())
  }

  return output.join(' ')
}

export const stringWithDashFormatter = (str: string) => {
  const strr = str.toLowerCase().split('_')

  const [firstStr, secondStr] = strr

  return (
    firstStr.charAt(0).toUpperCase() +
    firstStr.slice(1).toLowerCase() +
    ' ' +
    secondStr.toUpperCase()
  )
}
export const stringWithDashFormatter2 = (str: string) => {
  const strr = str.toLowerCase().split('_')

  const [firstStr, secondStr] = strr

  return (
    firstStr.charAt(0).toUpperCase() +
    firstStr.slice(1).toLowerCase() +
    ' ' +
    secondStr.charAt(0).toUpperCase() +
    secondStr.slice(1).toLowerCase()
  )
}

export const hasNumber = (myString: string) => {
  return /\d/.test(myString)
}

export const hasLowerCase = (str: string) => {
  return /[a-z]/.test(str)
}

export const hasUpperCase = (str: string) => {
  return /[A-Z]/.test(str)
}

export const hasSpecialCharacter = (str: string) => {
  return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str)
}

export function toTitleCase(str: any) {
  if (str === 'string') {
    return 'N/A'
  }
  if (str) {
    return str.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}
