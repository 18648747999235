import { lazy } from 'react'
import { BasePaths } from '@/constants/paths'
import MainLayout from '@/layout/DashboardLayout'

const BaseRoutes = [
  {
    path: '/*',
    exact: true,
    component: lazy(() => import('@/modules/authentication/routes')),
    Layout: null,
    useAuth: false,
  },
  {
    path: `${BasePaths.ADMIN}/*`,
    exact: true,
    component: lazy(() => import('@/modules/admin/routes')),
    Layout: MainLayout,
    useAuth: true,
  },
  {
    path: `${BasePaths.AGGREGATOR}/*`,
    exact: true,
    component: lazy(() => import('@/modules/aggregator/routes')),
    Layout: MainLayout,
    useAuth: true,
  },
]

export default BaseRoutes
