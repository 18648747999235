import { DashboardPath } from './paths'
import { ReactComponent as Person } from '@/assets/svgs/person.svg'
import { ReactComponent as Overview } from '@/assets/svgs/overview.svg'
import { ReactComponent as Support } from '@/assets/svgs/support.svg'
import { ReactComponent as Retailers } from '@/assets/svgs/retailers.svg'
import { ReactComponent as Gear } from '@/assets/svgs/gear.svg'
import { ReactComponent as LogoutRed } from '@/assets/svgs/logoutRed.svg'
import { ReactComponent as Aggregators } from '@/assets/svgs/aggregators.svg'
import { ReactComponent as Request } from '@/assets/svgs/request.svg'
import { ReactComponent as FieldAgent } from '@/assets/svgs/field_agent.svg'
import { ReactComponent as MicrosavingIcon } from '@/assets/svgs/house.svg'
import { ReactComponent as InventoryIcon } from '@/assets/svgs/inventory.svg'

type SvgInHtml = typeof Person

interface NavLInk {
  name: string
  path: string
  Icon: SvgInHtml
}

const ADMIN_OVERVIEW_SIDE_NAVS: NavLInk = {
  name: 'Overview',
  path: DashboardPath.OVERVIEWS,
  Icon: Overview,
}
const ADMIN_REQUEST_SIDE_NAVS: NavLInk[] = [
  {
    name: 'Requests',
    path: DashboardPath.REQUESTS,
    Icon: Request,
  },
  {
    name: 'Terminals',
    path: DashboardPath.TERMINALS,
    Icon: Request,
  },
]

const ADMIN_SERVICE_SIDE_NAVS: NavLInk[] = [
  // {
  //   name: 'Microsavings',
  //   path: DashboardPath.MICROSAVINGS,
  //   Icon: Retailers,
  // },
  // {
  //   name: 'Micropension',
  //   path: DashboardPath.MICROPENSION,
  //   Icon: InventoryIcon,
  // },
  //
  // {
  //   name: 'Microinsurance',
  //   path: DashboardPath.MICROINSURANCE,
  //   Icon: Aggregators,
  // },
  // {
  //   name: 'Microinvestment',
  //   path: DashboardPath.MICROINVESTMENT,
  //   Icon: Person,
  // },
]

const ADMIN_USER_SIDE_NAVS: NavLInk[] = [
  {
    name: 'Retailers',
    path: DashboardPath.RETAILERS,
    Icon: InventoryIcon,
  },
  // { name: 'Customer', path: DashboardPath.CUSTOMERS, Icon: InventoryIcon },
  {
    name: 'Aggregators',
    path: DashboardPath.AGGREGATOR,
    Icon: Aggregators,
  },

  { name: 'Field Agent', path: DashboardPath.AGENTS, Icon: FieldAgent },
]

const AGGREGATOR_SIDE_NAVS: NavLInk[] = [
  { name: 'Overview', path: DashboardPath.OVERVIEW, Icon: Overview },
  {
    name: 'Retailers',
    path: DashboardPath.RETAILERS,
    Icon: Retailers,
  },
]

const OTHER_ADMIN_SIDE_NAVS: NavLInk[] = [
  // { name: 'Settings', path: DashboardPath.SETTINGS, Icon: Gear },
  // {
  //   name: 'Help & Support',
  //   path: DashboardPath.SUPPORT,
  //   Icon: Support,
  // },
  {
    name: 'Logout',
    path: 'logout',
    Icon: LogoutRed,
  },
]

export {
  AGGREGATOR_SIDE_NAVS,
  OTHER_ADMIN_SIDE_NAVS,
  ADMIN_USER_SIDE_NAVS,
  ADMIN_SERVICE_SIDE_NAVS,
  ADMIN_OVERVIEW_SIDE_NAVS,
  ADMIN_REQUEST_SIDE_NAVS,
}
