import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from '@/helpers/auth'

function PrivateRoute({ children }: any) {
  const location = useLocation()
  const isAuthenticatedUser = isAuthenticated()
  if (!isAuthenticatedUser) {
    return <Navigate to='/' state={{ from: location }} replace />
  }
  return children
}

export default PrivateRoute

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}
