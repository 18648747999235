import React, { useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { makeStyles } from '@mui/styles'

interface IBoostaMultiSelectProps {
  options: { label: string; value: string }[]
  overrideStrings?: { [key: string]: string }
}

const useStyles = makeStyles({
  root: {
    width: '90%',
    marginRight: '4px',
    position: 'relative',
    zIndex: 999,
    border: 'none !important',
    outline: 'none !important',
    '&:focus': {
      outline: 'none',
    },
    '& rmsc dropdown-container:focus-within': {
      boxShadow: 'none',
      boxColor: 'transparent',
      color: 'transparent',
    },
    'inpute:focus': {
      outline: 'none',
    },
  },
})

const BoostaMultiSelect = ({ options, ...props }: IBoostaMultiSelectProps) => {
  const [selected, setSelected] = useState([])
  const classes = useStyles()

  return (
    <MultiSelect
      className={classes.root}
      options={options}
      value={selected}
      onChange={setSelected}
      labelledBy='Select'
      {...props}
    />
  )
}

export default BoostaMultiSelect
