/* eslint-disable */
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, SvgIcon } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const DatePicker = ({ error, helperText, sx, ...rest }: any) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        disableMaskedInput
        onClose={() => setOpen(false)}
        className={classes.root}
        components={{
          OpenPickerIcon: KeyboardArrowDownIcon,
        }}
        {...rest}
        renderInput={(params) => (
          <TextField
            // inputProps={{ readOnly: true }}
            onClick={() => setOpen(true)}
            fullWidth
            {...params}
            className={classes.root}
            sx={sx}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePicker

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },

    '& input::placeholder': {
      opacity: 0,
    },

    '& .MuiOutlinedInput-root .Mui-disabled': {
      background: '#EDEBE9',
    },

    '& .MuiSvgIcon-root': {
      color: 'transparent',
      fontSize: 15,
      stroke: '#8A8886',
    },
  },
}))
