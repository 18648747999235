import { useState, useContext, useEffect } from 'react'

import { AuthContext } from '@/context/authContext'

import { getDecodedJwt, isAuthenticated, removeToken } from '@/helpers/auth'

export const useProvideAuth = () => {
  const [user, setUser] = useState<any>(null)
  const [userDetails, setuserDetails] = useState<any>(null)

  useEffect(() => {
    const isAuthenticatedUser: any = isAuthenticated()
    // console.log({ isAuthenticated })
    if (isAuthenticatedUser) {
      const decodedUser: any = getDecodedJwt()
      // console.log({ jjj: decodedUser })
      setUser(decodedUser.roles)
      setuserDetails(decodedUser)
    }
  }, [user?.roles])

  const _setUser = async (payload: any) => {
    const decodedUser: any = getDecodedJwt()
    setUser(decodedUser.roles)
    setuserDetails(decodedUser)
  }

  const signOut = () => {
    removeToken()
    setuserDetails(null)
    return setUser(false)
  }

  return { user, userDetails, _setUser, signOut }
}

const useAuth = () => {
  const auth = useContext(AuthContext)
  return { auth }
}

export default useAuth
