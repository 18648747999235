import { NavLink, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  ADMIN_OVERVIEW_SIDE_NAVS,
  ADMIN_REQUEST_SIDE_NAVS,
  ADMIN_SERVICE_SIDE_NAVS,
  // ADMIN_SIDE_NAVS,
  ADMIN_USER_SIDE_NAVS,
  AGGREGATOR_SIDE_NAVS,
  OTHER_ADMIN_SIDE_NAVS,
} from '@/constants/sideNav'
import { logOut } from '@/helpers/auth'
import useAuth from '@/hooks/custom-hooks/useAuth'
import { useEffect, useState } from 'react'

const SIDENAVS = {
  Aggregator: AGGREGATOR_SIDE_NAVS,
  Other: OTHER_ADMIN_SIDE_NAVS,
  User: ADMIN_USER_SIDE_NAVS,
  Service: ADMIN_SERVICE_SIDE_NAVS,
  Overview: ADMIN_OVERVIEW_SIDE_NAVS,
  Request: ADMIN_REQUEST_SIDE_NAVS,
}

const Sidebar = () => {
  const location = useLocation()
  const pathName = location.pathname.split('/')[2]
  const auth: any = useAuth()
  const [user, setUser] = useState(null)

  useEffect(() => {
    setUser(auth.auth.user[0])
    // eslint-disable-next-line
  }, [])

  return (
    <Box component='aside' className='sidenav' sx={{ pt: 11 }}>
      {user === 'ADMIN' && (
        <>
          <Box>
            <NavLink
              to={SIDENAVS.Overview.path}
              key={SIDENAVS.Overview.name}
              style={{ display: 'flex', alignItems: 'center' }}
              className={clsx({
                border: pathName === SIDENAVS.Overview.path,
              })}
            >
              <SIDENAVS.Overview.Icon
                fill={pathName === SIDENAVS.Overview.path ? '#fff' : '#FFF'}
                stroke={
                  pathName === SIDENAVS.Overview.path ? '#fff' : '#6B6C7E'
                }
                width={20}
                height={20}
              />
              <Typography
                color={pathName === SIDENAVS.Overview.path ? '#fff' : '#6B6C7E'}
                variant='body1'
              >
                {SIDENAVS.Overview.name}
              </Typography>
            </NavLink>
          </Box>
          <Divider sx={{ width: '90%', m: '0px auto 1.5em' }} />
        </>
      )}
      {/*{user === 'ADMIN' && (*/}
      {/*  <Box sx={{ flexGrow: 1 }}>*/}
      {/*    <Typography*/}
      {/*      color={'#6B6C7E'}*/}
      {/*      variant='body1'*/}
      {/*      style={{*/}
      {/*        display: 'flex',*/}
      {/*        alignItems: 'center',*/}
      {/*        width: '80%',*/}
      {/*        margin: 'auto',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      SERVICE*/}
      {/*    </Typography>*/}
      {/*    {SIDENAVS.Service?.map((sidenav) => (*/}
      {/*      <NavLink*/}
      {/*        to={sidenav.path}*/}
      {/*        key={sidenav.name}*/}
      {/*        style={{ display: 'flex', alignItems: 'center' }}*/}
      {/*        className={clsx({*/}
      {/*          border: pathName === sidenav.path,*/}
      {/*        })}*/}
      {/*      >*/}
      {/*        <sidenav.Icon*/}
      {/*          fill={pathName === sidenav.path ? '#fff' : '#FFF'}*/}
      {/*          stroke={pathName === sidenav.path ? '#fff' : '#6B6C7E'}*/}
      {/*          width={20}*/}
      {/*          height={20}*/}
      {/*        />*/}
      {/*        <Typography*/}
      {/*          color={pathName === sidenav.path ? '#fff' : '#6B6C7E'}*/}
      {/*          variant='body1'*/}
      {/*        >*/}
      {/*          {sidenav.name}*/}
      {/*        </Typography>*/}
      {/*      </NavLink>*/}
      {/*    ))}*/}
      {/*  </Box>*/}
      {/*)}*/}
      {user === 'ADMIN' && (
        <Box sx={{ pb: 5 }}>
          <Typography
            color={'#6B6C7E'}
            variant='body1'
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: 'auto',
            }}
          >
            USERS
          </Typography>
          {SIDENAVS.User?.map((sidenav) => (
            <NavLink
              to={sidenav.path}
              key={sidenav.name}
              style={{ display: 'flex', alignItems: 'center' }}
              className={clsx({
                border: pathName === sidenav.path,
              })}
            >
              <sidenav.Icon
                fill={pathName === sidenav.path ? '#fff' : '#FFF'}
                stroke={pathName === sidenav.path ? '#fff' : '#6B6C7E'}
                width={20}
                height={20}
              />
              <Typography
                color={pathName === sidenav.path ? '#fff' : '#6B6C7E'}
                variant='body1'
              >
                {sidenav.name}
              </Typography>
            </NavLink>
          ))}
        </Box>
      )}
      {user === 'ADMIN' && (
        <Box sx={{ flexGrow: 1, pb: 5 }}>
          <Typography
            color={'#6B6C7E'}
            variant='body1'
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: 'auto',
            }}
          >
            OTHERS
          </Typography>

          {SIDENAVS.Request?.map((sidenav) => (
            <NavLink
              to={sidenav.path}
              key={sidenav.name}
              style={{ display: 'flex', alignItems: 'center' }}
              className={clsx({
                border: pathName === sidenav.path,
              })}
            >
              <sidenav.Icon
                fill={pathName === sidenav.path ? '#fff' : '#FFF'}
                stroke={pathName === sidenav.path ? '#fff' : '#6B6C7E'}
                width={20}
                height={20}
              />
              <Typography
                color={pathName === sidenav.path ? '#fff' : '#6B6C7E'}
                variant='body1'
              >
                {sidenav.name}
              </Typography>
            </NavLink>
          ))}

          {/* <NavLink
            to={SIDENAVS.Request.path}
            key={SIDENAVS.Request.name}
            style={{ display: 'flex', alignItems: 'center' }}
            className={clsx({
              border: pathName === SIDENAVS.Request.path,
            })}
          >
            <SIDENAVS.Request.Icon
              fill={pathName === SIDENAVS.Request.path ? '#fff' : '#FFF'}
              stroke={pathName === SIDENAVS.Request.path ? '#fff' : '#6B6C7E'}
              width={20}
              height={20}
            />
            <Typography
              color={pathName === SIDENAVS.Request.path ? '#fff' : '#6B6C7E'}
              variant='body1'
            >
              {SIDENAVS.Request.name}
            </Typography>
          </NavLink> */}
        </Box>
      )}
      <Box>
        {user === 'AGGREGATOR' &&
          SIDENAVS.Aggregator?.map((sidenav) => (
            <NavLink
              to={sidenav.path}
              key={sidenav.name}
              style={{ display: 'flex', alignItems: 'center' }}
              className={clsx({
                border: pathName === sidenav.path,
              })}
            >
              <sidenav.Icon
                fill={pathName === sidenav.path ? '#fff' : '#FFF'}
                stroke={pathName === sidenav.path ? '#fff' : '#6B6C7E'}
                width={20}
                height={20}
              />
              <Typography
                color={pathName === sidenav.path ? '#fff' : '#6B6C7E'}
                variant='body1'
              >
                {sidenav.name}
              </Typography>
            </NavLink>
          ))}
      </Box>
      <Box className='setting' sx={{ mb: 2 }}>
        <Divider sx={{ width: '90%', m: '0px auto 1.5em' }} />
        {SIDENAVS.Other.map(({ name, path, Icon }) => (
          <NavLink
            to={path}
            key={name}
            style={{ display: 'flex', alignItems: 'center' }}
            className={clsx({
              border: pathName === path,
            })}
            onClick={() => name === 'Logout' && logOut()}
          >
            <Icon
              fill={pathName === path ? '#fff' : '#fff'}
              stroke={pathName === path ? '#fff' : '#6B6C7E'}
              width={20}
              height={20}
            />
            <Typography
              color={pathName === path ? '#fff' : '#6B6C7E'}
              variant='body1'
            >
              {name}
            </Typography>
          </NavLink>
        ))}
      </Box>
    </Box>
  )
}

export default Sidebar
