import { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import useStyles from '@/layout/DashboardLayout/DashboardLayouts'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'

type Props = {
  children: ReactNode
}

const DashboardLayout: FC<Props> = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Header />
      <Grid container spacing={0}>
        <Grid item sm={2} sx={{ position: 'fixed', width: '40%' }}>
          <Sidebar />
        </Grid>
        <Grid item sm={10} sx={{ ml: '17%', mt: 8 }}>
          <Box component='main' className='main'>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardLayout
