import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Suspense } from 'react'
import BaseRoutes from '@/constants/baseRoute'

import PrivateRoute from '@/routes/PrivateRoute'
import AuthContextProvider from '@/context/authContext'
import Loader from '@/reusables/common/Loader'
import ModalContextProvider from '@/context/modalContext'
import DrawerContextProvider from '@/context/drawerContext'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import PageNotFound from "@/modules/authentication/PageNotFound";

import ErrorBoundary from '@/ErrorBoundary'

const RenderRoutes = function () {
  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <ModalContextProvider>
          <DrawerContextProvider>
            <BrowserRouter>
              <Suspense fallback={<Loader />}>
                <Routes>
                  {BaseRoutes.map(
                    ({ path, Layout, useAuth, component: Component }) =>
                      Layout && useAuth ? (
                        <Route
                          key={path}
                          path={path}
                          element={
                            <PrivateRoute>
                              <Layout key={path}>
                                <Component />
                              </Layout>
                            </PrivateRoute>
                          }
                        />
                      ) : (
                        <Route key={path} path={path} element={<Component />} />
                      )
                  )}
                  <Route path='pageNotFound' element={<PageNotFound />} />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </DrawerContextProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  )
}
export default RenderRoutes
