import React, { Component, ErrorInfo, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import Logo from '@/assets/svgs/logo.svg'
import { ReactComponent as ErrorBoundaryImg } from '@/assets/svgs/errorBoundaryImg.svg'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <AppBar position='fixed' color='transparent'>
            <Toolbar>
              <Grid container alignItems='center' sx={{ mx: 0 }}>
                <Grid
                  item
                  md={6}
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Grid container alignItems='center'>
                    <Grid item md={4}>
                      <img src={Logo} alt='logo' style={{ height: '60px' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Box>
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              sx={{ mt: 21 }}
            >
              <Grid sx={{ mr: 11 }}>
                <Typography
                  fontSize='46px'
                  color='#000000'
                  sx={{ fontWeight: 500, lineHeight: 1.2 }}
                >
                  Oops, Something <br />
                  went wrong
                </Typography>
                <Typography
                  fontSize='20px'
                  color='#333'
                  sx={{
                    fontWeight: 400,
                    mt: 2,
                    lineHeight: '1.4',
                    maxWidth: '400px',
                  }}
                >
                  We encountered an error while processing your request. Please
                  try again later. <br />
                </Typography>
                <Typography
                  fontSize='16px'
                  color='#333'
                  sx={{
                    fontWeight: 300,
                    mt: 2,
                    lineHeight: '1.4',
                    maxWidth: '400px',
                  }}
                >
                  If the issue persists, contact our support team at <br />
                  <a href='mailto:support@booosta.com'>
                    support@booosta.com
                  </a>{' '}
                  for assistance.
                </Typography>

                <Typography
                  fontSize='16px'
                  color='#333333'
                  sx={{
                    fontWeight: 300,
                    mt: 2,
                    lineHeight: '1.4',
                    maxWidth: '400px',
                  }}
                >
                  Apologies for the inconvenience.
                </Typography>
              </Grid>
              <Grid>
                <ErrorBoundaryImg style={{ width: 570, height: 570 }} />
              </Grid>
            </Grid>
          </Box>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
