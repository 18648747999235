/* eslint-disable camelcase */
import { IDecodedUser } from '@/models/IAuthState'
import jwtDecode from 'jwt-decode'

export const setToken = (token: string): void => {
  localStorage.setItem('accessToken', token)
}

export const getToken = (): string | null => {
  return localStorage.getItem('accessToken')
}

export const getDecodedJwt = (tokn: string = ''): IDecodedUser => {
  try {
    const token = getToken() || ''
    const t = tokn || token
    const decoded = jwtDecode<IDecodedUser>(t)
    return decoded
  } catch (error) {
    return {} as IDecodedUser
  }
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refreshToken', refreshToken)
}

export const getRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken')
}

export const removeDomainObj = (): void => {
  localStorage.removeItem('domain')
}

export const removeToken = (): void => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

export const logOut = (cb?: VoidFunction): void => {
  console.log('log out ')
  removeToken()
  cb?.()
}

export const isAuthenticated = () => {
  try {
    const decodedToken = getDecodedJwt()

    if (decodedToken.exp) {
      const { exp } = decodedToken
      const currentTime = Date.now() / 1000
      return exp > currentTime
    }
    return false
  } catch (e) {
    return false
  }
}
