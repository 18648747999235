import { useContext } from 'react'

import { ModalContext } from '@/context/modalContext'

const useModal = () => {
  const { state, setState }: any = useContext(ModalContext)

  return [state, setState]
}

export default useModal
