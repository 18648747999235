import { FC } from 'react'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

type Props = {
  open: boolean
  handleClose: () => void
  message: string
  severity: any
}

const useStyles = makeStyles({
  root: {
    '& .MuiAlert-filledSuccess': {
      border: '1px solid #D4F7DC',
    },
    '& .MuiAlert-filledError': {
      border: '1px solid #FEEFEF',
    },
  },
})

const Index: FC<Props> = ({ open, handleClose, message, severity }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
      >
        <Alert icon={false} severity={severity} variant='filled'>
          <Typography variant='body1'>{message}</Typography>
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Index
