import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import themes from './theme'
import RenderRoutes from './routes'
import { NotificationProvider } from './context/NotificationProvider'
import { CssBaseline } from '@mui/material'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 5000,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
})

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={themes(0)}>
        <CssBaseline>
          <NotificationProvider>
            <QueryClientProvider client={queryClient}>
              <RenderRoutes />
            </QueryClientProvider>
          </NotificationProvider>
        </CssBaseline>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
