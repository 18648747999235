import { Menu as MuiMenu } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import { withStyles } from '@mui/styles'

const StyledMenu = withStyles({
  paper: {
    padding: '5px 15px',
    width: 'auto',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
    borderRadius: '4px',
    background: '#fff',
    '& .other': {
      borderTop: '1px solid #FAFAFA',
      padding: '.4em 3em .4em .4em',
      fontSize: '12.64px',
      lineHeight: '14px',
      color: '#737373',
      cursor: 'pointer',
      borderRadius: '0.2rem'
    },
    '& .delete': {
      color: '#DC0000',
      padding: '.4em 3em .4em 0px',
      fontSize: '12.64px',
      lineHeight: '14px',
      cursor: 'pointer',
    },
    '& .primary': {
      color: '#15692A',
      padding: '.4em 3em .4em 0px',
      fontSize: '12.64px',
      lineHeight: '14px',
      cursor: 'pointer',
    },
    '& .grey-bg': {
      '&:hover': {
        backgroundColor: '#efefef85 !important'
      }
    }
  },
})((props) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={true}
    {...props}
  />
))

function Menu({ handlePopeverClose, anchorEl, menuItems, ...otherProps }: any) {
  return (
    <StyledMenu
      id='customized-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopeverClose}
      {...otherProps}
    >
      {menuItems.map((row: any) => (
        <ListItemText
          key={row.name}
          primary={row.name}
          className={row.className}
          onClick={row.action}
        />
      ))}
    </StyledMenu>
  )
}

export default Menu
