import React, { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useProvideAuth } from '@/hooks/custom-hooks/useAuth'

export const AuthContext = createContext({})

function AuthContextProvider({ children }: any) {
  const { user, userDetails, _setUser, signOut } = useProvideAuth()

  const value = useMemo(
    () => ({
      user,
      userDetails,
      _setUser,
      signOut,
    }),
    [user, userDetails, _setUser, signOut]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContextProvider

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
