/* eslint-disable */
import {
  Table as MuiTable,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  styled,
  Button,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SetStateAction, Key, useMemo, useState } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ClearIcon from '@mui/icons-material/Clear'
import TableHead, { SortOrder } from './TableHead'
import TablePagination from './TablePagination'

const Table = ({
  dataSource,
  columns,
  paginationPosition,
  tableParams,
  setTableParams,
  onRow,
  loading,
  setOpenFilters,
  hasFilter,
  clearFilter,
  clearFilterBtn,
}: any) => {
  const [order, setOrder] = useState(SortOrder.DEF)
  const [orderBy, setOrderBy] = useState(null)

  const dataKeys = useMemo(() => {
    return dataSource.map((item: { id: any }, index: any) => item.id || index)
  }, [dataSource])

  const handleSort = (_e: any, property: SetStateAction<null>) => {}

  const renderCellData = (
    column: any,
    row: { [x: string]: any },
    index: any
  ) => {
    if (column.render)
      return column.render(row[column?.key], row, index) ?? null
    return row[column?.key] ?? null
  }

  const handleRowClick = (row: any, rowIndex: any) => {
    return (event: any) => {
      onRow?.(row, rowIndex)?.onClick(event)
    }
  }

  const renderDataItems = () => {
    return dataSource.map((row: any, rowIndex: string | number) => {
      const key = dataKeys[rowIndex]
      return (
        <TableRow hover key={rowIndex} onClick={handleRowClick(row, rowIndex)}>
          {columns.map(
            (
              column: {
                key: Key | null | undefined
                align: string | undefined
                width: any
              },
              index: number
            ) => (
              <TableCell
                key={index}
                align={'left'}
                style={{ width: column.width }}
              >
                {renderCellData(column, row, rowIndex)}
              </TableCell>
            )
          )}
        </TableRow>
      )
    })
  }

  const renderTablePagination = () => (
    <TablePagination
      tableParams={tableParams}
      setTableParams={setTableParams}
    />
  )

  const renderTableHeader = () => (
    <Box sx={{ padding: '8px 16px' }}>
      <Box display='flex' justifyContent='space-between'>
        {hasFilter && (
          <Box display='flex' justifyContent='space-between'>
            <Button
              sx={{ marginLeft: '24px' }}
              size='large'
              onClick={() => setOpenFilters(true)}
            >
              <FilterAltIcon sx={{ marginRight: '10px' }} /> Filter
            </Button>
            <Button
              sx={{
                marginLeft: '15px',
                display: clearFilterBtn ? 'flex' : 'none',
              }}
              size='large'
              onClick={clearFilter}
            >
              <ClearIcon /> Clear Filter
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )

  return (
    <Box>
      <Paper square>
        {renderTableHeader()}
        {!loading ? (
          <TableWrap elevation={1} square>
            {dataSource.length === 0 ? (
              <Box
                height='200px'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Typography textAlign='center'>No data found</Typography>
              </Box>
            ) : (
              <MuiTable>
                <TableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  onSort={handleSort}
                />

                <TableBody>{renderDataItems()}</TableBody>
              </MuiTable>
            )}
            {paginationPosition === 'footer' && renderTablePagination()}
          </TableWrap>
        ) : (
          <Box
            height='400px'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Box>
        )}
      </Paper>
    </Box>
  )
}

const TableWrap = styled(Paper)(() => ({
  '& .MuiTableCell-body': {
    cursor: 'pointer',
  },
}))

export default Table
