import React, { useState, createContext, useMemo, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const ModalContext = createContext({
  modalName: '',
  message: '',
  redirect: '',
  id: null,
  subMessage: '',
})

function ModalContextProvider({ children }: Props) {
  const [state, setState] = useState({
    modalName: '',
    message: '',
    redirect: '',
    id: null,
    subMessage: '',
  })

  const value: any = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  )
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContextProvider
