/* eslint-disable */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { TextField } from '@/reusables/common'
import { makeStyles } from '@mui/styles'

import FilterBoxes from '../FilterBoxes'

const useStyles = makeStyles({
  root: {
    height: '43px',
  },
})

function SearchAndFilters({
  filters,
  showFilters,
  getFilterValue,
  date,
  onChange,
  isDate,
  filterBackgroundColor,
}: any) {
  const { control } = useForm({})
  const classes = useStyles()
  const values = filters?.map((fil: { label: string }) => {
    return {
      name: fil.label,
      value: '',
      isOpen: false,
      checked: false,
      checkedValue: [],
    }
  })
  const [searchValue, setSearchValue] = useState(values)

  const handleClose = (label: string) => {
    const result = searchValue?.map((el: { name: string; isOpen: boolean }) => {
      if (el.name === label) {
        el.isOpen = !el.isOpen
      } else {
        el.isOpen = false
      }
      return el
    })
    setSearchValue(result)
  }

  const filteredSearchResult = (list = [], search: string) => {
    if (Boolean(search)) {
      let filteredList = list.filter(({ value }: any) => {
        let filtered = value?.toLowerCase()?.includes(search?.toLowerCase())
        return filtered
      })
      return filteredList
    }
    return list
  }

  const handleChecked = (e: any, selected: any, id: any, index: any) => {
    const ids = filters
      ?.map(({ options }: any) => options)
      [index]?.map(({ id }: any) => id)

    if (e.target.checked) {
      if (id === 'all') {
        const result = searchValue?.map((el: any) => {
          if (el.name === selected.name) {
            el.checkedValue = ids
            el.checked = !el.checked
          }
          return el
        })
        setSearchValue(result)
        return
      }
      const result = searchValue?.map((el: any) => {
        if (el.name === selected.name) {
          el.checkedValue.push(id)
          el.checked = !el.checked
        }
        return el
      })
      setSearchValue(result)
    } else {
      if (id === 'all') {
        const result = searchValue?.map((el: any) => {
          if (el.name === selected.name) {
            el.checkedValue = []
            el.checked = !el.checked
          }
          return el
        })
        setSearchValue(result)
        return
      }
      const result = searchValue?.map((el: any) => {
        if (el.name === selected.name) {
          el.checkedValue = el.checkedValue?.filter((un: any) => un !== id)
          el.checked = !el.checked
        }
        return el
      })
      setSearchValue(result)
    }
  }

  return (
    <Grid container spacing={4} alignItems='center'>
      <Grid item xs={12} md={3}>
        <TextField
          className={classes.root}
          control={control}
          name='search'
          label='Search'
          type='search'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <FilterBoxes
          filters={filters}
          handleClose={handleClose}
          searchValue={searchValue}
          filteredSearchResult={filteredSearchResult}
          handleChecked={handleChecked}
          getFilterValue={getFilterValue}
          isDate={isDate}
          showFilters={showFilters}
          date={date}
          onChange={onChange}
          filterBackgroundColor={filterBackgroundColor}
        />
      </Grid>
    </Grid>
  )
}

export default SearchAndFilters
